<template>
  <div>
    <template v-if="ageGroupPrograms.length">
      <p class="font-body-normal align-center">
        Which care schedule are you interested in? Select all that apply.
      </p>
    </template>
    <template v-else>
      <p class="align-center">
        There are no care schedules available that are a fit for your child.
      </p>
    </template>
    <article class="programCard" v-for="program in ageGroupPrograms" :key="program.id">
      <label class="custom">
        <input type="checkbox" v-model="selectedSchedule" :value="program.id" />
        <span class="fa fa-check-circle"></span>
        <schedule :program="program" :key="program.id" class="facility__bordered-box" />
      </label>
    </article>
  </div>
</template>

<script>
import Schedule from '@components/legup/schedule.vue';

export default {
  name: 'select-care-schedules',
  components: {
    Schedule,
  },
  props: {
    tourData: {
      type: Object,
      default() {
        return {};
      },
    },
    ageGroupPrograms: {
      type: Array,
      default() {
        return [];
      },
    },
    bus: {
      type: Object,
      required: true,
    },
    submitStep: {
      type: Function,
      required: true,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      selectedSchedule: this.tourData.selectedSchedule || [],
    };
  },
  created() {
    this.bus.$on('on-next-step', this.handleNext);
  },
  beforeDestroy() {
    this.bus.$off('on-next-step', this.handleNext);
  },
  watch: {
    selectedSchedule() {
      this.onChange(this.$data);
    },
  },
  methods: {
    handleNext() {
      this.submitStep();
    },
  },
};
</script>

<style lang="scss" scoped>
.custom {
  width: 100%;
  padding: calc(var(--grid-unit) * 2) calc(var(--grid-unit) * 3);

  &:hover {
    cursor: pointer;
  }

  span {
    position: absolute;
    top: -10px;
    right: -5px;
    display: none;
    background-color: white;
  }

  input {
    display: none;

    &:checked ~ span {
      display: inline;
      font-size: 20px;
      color: var(--viridian-50);
    }
  }
}

.programCard {
  position: relative;
  border: 1px solid var(--gray-20);
  border-radius: calc(var(--grid-unit));

  + .programCard {
    margin-top: calc(var(--grid-unit) * 2);
  }
}

.programCard:has(> .custom input:checked) {
  border: 1px solid var(--viridian-50) !important;
}

.align-center {
  margin-bottom: 20px;
  text-align: center;
}
</style>
