<template>
  <modal
    :is-open="isOpen"
    @close="closeModal"
  >
    <template v-if="!declineFlow || noOffer" #header>
      <h6 class="header">edit waitlist preferences</h6>
      <strong class="center-name">{{centerName}}</strong>
    </template>
    <div v-if="declineFlow && !noOffer">
      <offer-declined-badge />
    </div>
    <div class="summary-table--border">
      <table>
        <tbody>
          <tr>
            <th class="summary-table--headers">Name</th>
            <td>{{childName}}</td>
          </tr>
          <tr>
            <th class="summary-table--headers">Waitlist join date</th>
            <td>{{formatDate(waitlistSpot.addDate)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="declineFlow && !noOffer">
      <h6 class="mb-4">You are still on the waitlist for {{ centerName }}</h6>
      <p>Need to update your preferences?</p>
    </div>
    <k-form-field label="Preferred start date">
      <date-input
        v-model="selectedDate"
        :value="waitlistSpot.preferredDate"
        :min="waitlistSpot.preferredDate"
        :disabled="removeFromWaitlist"
      />
    </k-form-field>
    <k-form-field label="Preferred schedule">
      <article class="programCard" v-for="program in ageGroupPrograms" :key="program.id">
        <k-checkbox
          v-model="selectedSchedule"
          :value="program.id"
          :disabled="removeFromWaitlist"
          class="mt-1"
        >
          <schedule
            :program="program"
            :key="program.id"
            :editFlow="true"
          />
        </k-checkbox>
      </article>
    </k-form-field>
    <k-form-field label="No longer want to be on this waitlist?">
      <Box color="rust">
        <k-checkbox v-model="removeFromWaitlist">
          Remove me from this provider's waitlist
        </k-checkbox>
      </Box>
    </k-form-field>
    <template #footer>
      <div>
        <system-button
          v-show="!declineFlow"
          style-type="clear"
          @click="closeModal"
        >
          Cancel
        </system-button>
        <system-button
          v-show="removeFromWaitlist"
          @click="handleEditOrDecline()"
          color="rust"
        >
          Remove me from the waitlist
        </system-button>
        <system-button
          v-show="!removeFromWaitlist"
          @click="handleEditOrDecline()"
          :disabled="noSelectedSchedules">
          {{ handleButtonText() }}
        </system-button>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@components/modal/modal.vue';
import SystemButton from '@components/buttons/system-button.vue';
import DateInput from '@components/inputs/date-input.vue';
import KFormField from '@components/forms/form_field.vue';
import KCheckbox from '@components/inputs/checkbox.vue';
import Schedule from '@components/legup/schedule.vue';
import OfferDeclinedBadge from '@components/badges/offer-declined.vue';
import Box from '@components/box';

export default {
  name: 'legup-waitlist',
  components: {
    Modal,
    SystemButton,
    DateInput,
    KFormField,
    KCheckbox,
    Schedule,
    OfferDeclinedBadge,
    Box,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    waitlistSpot: {
      type: Object,
      required: true,
    },
    centerId: {
      type: Number,
      required: true,
    },
    centerName: {
      type: String,
      required: true,
    },
    ageGroupId: {
      type: Number,
      required: true,
    },
    fetchOfferData: {
      type: Function,
    },
    childName: {
      type: String,
      required: false,
    },
    noOffer: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      ageGroupPrograms: [],
      removeFromWaitlist: false,
      selectedSchedule: [],
      selectedDate: this.waitlistSpot.preferredDate || '',
    };
  },
  computed: {
    noSelectedSchedules() {
      return this.selectedSchedule.length === 0;
    },
    declineFlow() {
      return Object.keys(this.declineOfferInfo).length;
    },
    declineOfferInfo() {
      return this.$store.getters['legup/getDeclineOfferInfo'];
    },
  },
  mounted() {
    this.assignAgeGroupPrograms();
  },
  methods: {
    async assignAgeGroupPrograms() {
      const programs = await this.$store.dispatch('legup/ageGroupProgramSchedules', { id: this.ageGroupId });
      this.ageGroupPrograms = programs;
      this.selectedSchedule = this.findSelectedSchedule();
    },
    findSelectedSchedule() {
      const programIds = this.ageGroupPrograms.filter((prog) => prog.id).map((prog) => prog.id);

      if (this.waitlistSpot.programs) {
        return this.waitlistSpot.programs
          .filter((wsp) => programIds.includes(wsp.programId))
          .map((wsp) => wsp.programId);
      }

      return [];
    },
    async updateOrRemoveWaitlistSpot() {
      const params = {
        selected_schedules: this.selectedSchedule,
        selected_date: this.selectedDate,
        remove_from_waitlist: this.removeFromWaitlist,
        waitlist_spot_id: this.waitlistSpot.id,
      };
      await this.$store.dispatch('legup/updateOrRemoveWaitlistSpot', params);

      if (this.removeFromWaitlist) {
        this.$store.commit('user/dependentsWithSpots', []);
        this.$store.commit('setWaitlistSpotCenters', []);
      }

      this.closeModal();
      this.clearData();
    },

    async passOrDeclineSeatOffer() {
      const params = {
        selected_schedules: this.selectedSchedule,
        selected_date: this.selectedDate,
        remove_from_waitlist: this.removeFromWaitlist,
        seat_id: this.declineOfferInfo.offerDetails.seatId,
        waitlist_spot_id: this.waitlistSpot.id,
        child_id: this.waitlistSpot.childId,
        parent_id: this.declineOfferInfo.offerDetails.parentId,
        center_id: this.declineOfferInfo.offerDetails.centerId,
        decline_reasons: this.declineOfferInfo.selectedReasons,
        decline_message: this.declineOfferInfo.declineMessage,
      };
      await this.$store.dispatch('legup/passOrDeclineSeatOffer', params);
      this.fetchOfferData(false);
      this.closeModal();
    },

    async handleEditOrDecline() {
      if (this.declineFlow) {
        await this.passOrDeclineSeatOffer();
      } else {
        await this.updateOrRemoveWaitlistSpot();
      }
    },

    clearData() {
      this.selectedSchedule = [];
      this.selectedDate = this.waitlistSpot.preferredDate;
      this.removeFromWaitlist = false;
    },

    handleButtonText() {
      if (this.declineFlow) {
        return 'Save and close';
      }

      return 'Save';
    },

    formatDate(date) {
      return this.$moment(new Date(date)).format('MMM DD, YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  font-family: var(--sans-serif);
  font-weight: 500;
  color: var(--viridian-5);
  text-align: center;
  text-transform: uppercase;
}

.center-name {
  font-family: var(--serif);
  font-size: 20px;
  font-weight: 800;
  color: var(--viridian-0);
  text-align: center;
}

.summary-table {
  &--border {
    border: 1px solid var(--gray-30);
    border-radius: 16px;
    padding: 16px;
  }

  &--headers {
    width: 65%;
    color: var(--gray-30);
    font-weight: normal;
  }
}
</style>
