export default {
  methods: {
    dependentsOlderThanFacilityAgeRange(userDependents) {
      let olderChildren = [];
      if (userDependents.length) {
        olderChildren = userDependents.filter(
          (d) => d.ageMonths > this.$store.state.legup.centerInfo?.age_months_to,
        ).map((d) => d.firstName);
      }
      return olderChildren;
    },
  },
};
