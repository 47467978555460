<template>
  <component :is="tag" class="button-container">
    <slot></slot>
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },
};
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  & > * {
    flex-shrink: 0;
    margin-bottom: var(--grid-unit);
    margin-left: var(--grid-unit);

    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }

    &:only-child {
      margin: 0 auto;
    }
  }
}
</style>
