<template>
  <tag color="viridian-light" fa-icon="circle-check fat">
    Offer declined
  </tag>
</template>

<script>
import Tag from '@components/tag/tag.vue';

export default {
  components: {
    Tag,
  },
};
</script>
