<template>
  <div class="checkbox-wrapper" data-cy="dependents-checkbox-list">
    <p v-if="withTitle">Children</p>
    <k-checkbox
      v-for="dependent in dependents"
      v-model="localState"
      :id="'dependent_' + dependent.id"
      :value="dependent.id"
      :key="`${dependent.nameAge} ${dependent.id}`"
    >
      {{ ageDisplay ? dependent.nameAge : dependent.firstNameDob }}
      <dependent-age-warning v-if="isTooOld(dependent.firstName)"/>
    </k-checkbox>

    <system-button
      small
      style-type="secondary"
      fa-icon="pencil"
      @click="onOpenModal"
      class="add-button"
    >
      Manage children
    </system-button>

    <manage-dependents-modal :is-open="showManageModal" @saved="onSaved" @hide="onHideModal"/>
  </div>
</template>

<script>
import SystemButton from '@components/buttons/system-button.vue';
import KCheckbox from '@components/inputs/checkbox.vue';
import { mapGetters } from 'vuex';
import ageWarning from '@mixins/ageWarning';
import ManageDependentsModal from './manage-dependents-modal.vue';
import dependentAgeWarning from './dependent-provider-age-warning.vue';

export default {
  components: {
    KCheckbox,
    ManageDependentsModal,
    SystemButton,
    dependentAgeWarning,
  },

  model: {
    prop: 'modelValue',
    event: 'change',
  },
  mixins: [ageWarning],
  props: {
    olderChildren: {
      type: Array,
      default() {
        return [];
      },
    },
    ageDisplay: {
      type: Boolean,
      default: true,
    },

    withTitle: {
      type: Boolean,
      default: false,
    },

    modelValue: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      showManageModal: false,
    };
  },

  computed: {
    ...mapGetters({
      dependents: 'dependents/asArray',
    }),

    localState: {
      get() { return Array.isArray(this.modelValue) ? this.modelValue : []; },
      set(localState) { this.emitChange(localState); },
    },
  },

  methods: {
    onSaved(ids) {
      this.emitChange([...this.modelValue, ...ids]);
      this.$el.dispatchEvent(new Event('change', { bubbles: true }));
      this.onHideModal();
    },

    onOpenModal() {
      this.showManageModal = true;
    },

    onHideModal() {
      this.showManageModal = false;
    },

    emitChange(value) {
      this.$emit('change', Array.from(new Set(value)));
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-wrapper::v-deep {
  & > div {
    margin-bottom: var(--grid-unit);
  }

  & > p {
    margin-bottom: 5px;
  }
}

.add-button {
  margin-top: var(--grid-unit);
}

.warning-text {
  color: red;
}
</style>
