<template>
  <div>
    <div
      :class="{ 'schedule-detail' : !editFlow }"
      v-for="d in scheduleDetails"
      :key="d">
      {{d}}
    </div>
  </div>
</template>

<script>
import {
  getDaysDescription, getTimeRange, getRangeInDollarFormat, getDollarFormat,
} from '@utils';

export default {
  name: 'schedule',
  props: {
    program: {
      type: Object,
      default() {
        return {};
      },
    },
    editFlow: {
      type: Boolean,
    },
  },
  computed: {
    price() {
      const { price, priceFrequency } = this.program;
      let per = '';

      if (priceFrequency.length === 1) {
        [per] = priceFrequency;
      }

      if (price.length === 0) {
        return '$ Ask for price';
      }

      if (price.length > 1) {
        return `${getRangeInDollarFormat(price[0], price[1])} / ${per}`;
      }

      return `${getDollarFormat(price[0])} / ${per}`;
    },
    scheduleDetails() {
      const daysOfWeek = getDaysDescription(this.program.daysOfTheWeek);
      const daysPerWeek = this.program.numberOfDays;
      const timeFrom = this.program.startTime;
      const timeTo = this.program.endTime;
      const timeRange = timeFrom && timeTo ? getTimeRange(timeFrom, timeTo) : undefined;
      return [
        daysPerWeek && daysOfWeek && `${daysPerWeek} day(s) ${daysOfWeek}`,
        timeRange && `${timeRange} | ${this.price}`,
      ].filter(Boolean);
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule-detail {
  margin-top: 8px;
}

.schedule-detail:nth-child(2) {
  color: var(--gray-60);
}
</style>
