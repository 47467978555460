import vue from 'vue';

export default {

  props: {
    timezone: {
      default: 'America/Los_Angeles',
    },
  },

  computed: {
    timeZoneAbbr() {
      return vue.moment.tz(this.timezone).format('z');
    },
  },
};
