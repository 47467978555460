<template>
  <div>
    <img
      height="80"
      class="mg-b-24"
      src="https://res.cloudinary.com/kinside/image/upload/v1648078117/app_assets/illustration-child1.png"
    >

    <h6 class="page__header font-heading-30 margin-center mg-b-16">
      Yippee! {{ successMsg }} with {{ facilityName }}!
    </h6>
    <box fa-icon="calendar" :no-icon-bg="true" color="viridian"
         class="center-content viridian-5 mg-b-16">
        {{ tourData.selectedTour.start | moment('timezone', timezone, 'LLL') }} {{ timeZoneAbbr }}
    </box>
    <p class="font-body-large">
      We'll send you a reminder email before {{ subMsg }} to  {{ parentEmail }}
    </p>
  </div>
</template>

<script>
import Box from '@components/box.vue';
import timeZoneAbbr from '../../../common/mixins/timeZoneAbbr';

export default {
  name: 'confirmtion',
  components: {
    Box,
  },
  props: {
    tourData: {
      type: Object,
      default() {
        return {};
      },
    },
    facilityName: {
      type: String,
    },
    bus: {
      type: Object,
      required: true,
    },
    submitStep: {
      type: Function,
      required: true,
    },
  },
  mixins: [timeZoneAbbr],
  created() {
    this.bus.$on('on-next-step', this.handleNext);
  },
  beforeDestroy() {
    this.bus.$off('on-next-step', this.handleNext);
  },
  methods: {
    handleNext() {
      this.submitStep();
    },
  },
  computed: {
    parentEmail() {
      const { email } = this.$store.getters['user/currentParent'] || {};
      return email;
    },
    successMsg() {
      if (this.tourData.selectedTour.isOpenHouse) {
        return "You're booked for an open house";
      }
      return 'You scheduled a tour';
    },
    subMsg() {
      if (this.tourData.selectedTour.isOpenHouse) {
        return 'the open house';
      }
      return 'your tour';
    },
  },
};
</script>

<style scoped>

.margin-center {
  margin: auto;
}

.viridian-5 {
  width: 60%;
  height: calc(var(--grid-unit));
  margin: 0 auto;
  color: var(--gray-80);
}

.mg-b-24 {
  margin-bottom: calc(var(--grid-unit) * 3);
}

.mg-b-16 {
  margin-bottom: calc(var(--grid-unit) * 2);
}

.center-content {
  justify-content: center;
}
</style>
