<template>
  <div :class="[classes, { 'tag--public': publicDirectory }]">
    <span v-if="withAsterisk" class="fas fa-asterisk"></span>
    <span v-if="faIcon" :class="iconClass"></span>
    <span class="tag__text" :class="{'hide-text': hideOnMobile}"><slot>{{text}}</slot></span>
    <span v-if="withAsterisk" class="fas fa-asterisk"></span>
  </div>
</template>

<script>
const COLORS = [
  'viridian',
  'viridian-light',
  'coral',
  'coral-light',
  'periwinkle',
  'periwinkle-dark',
  'periwinkle-light',
  'gold',
  'gold-light',
  'gray',
  'gray-light',
  'gray-dark',
  'rust-light',
];

export default {
  props: {
    text: {
      type: String,
    },

    color: {
      type: String,
      default: 'gold',
      validate(v) {
        return COLORS.includes(v);
      },
    },

    withAsterisk: {
      type: Boolean,
    },

    faIcon: {
      type: String,
    },

    hideOnMobile: {
      type: Boolean,
    },

    publicDirectory: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return `tag--${this.color} tag`;
    },

    iconClass() {
      return `fal fa-${this.faIcon}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.tag {
  display: inline-flex;
  align-items: center;
  padding: calc(var(--grid-unit) * calc(3 / 4)) calc(var(--grid-unit) * calc(5 / 4));
  font-family: var(--sans-serif);
  font-size: var(--font-size-0);
  line-height: var(--font-size-0);
  color: var(--gray-60);
  text-align: center;
  border-radius: var(--radius);
  box-shadow: var(--shadow-20);

  @media screen and (min-width: 400px) {
    font-size: var(--font-size-10);
    line-height: var(--font-size-10);
  }

  .fal:not(:only-child) {
    margin-right: calc(var(--grid-unit) / 2);
  }

  .hide-text {
    display: none;

    @media (min-width: 768px) {
      display: inline;
    }
  }

  /* stylelint-disable no-descending-specificity */
  .fa-asterisk {
    font-size: 13px;
    line-height: 13px;
    color: var(--gold-20);

    &:first-child {
      margin-right: calc(var(--grid-unit) / 2);
    }

    &:last-child {
      margin-left: calc(var(--grid-unit) / 2);
    }
  }

  &--gold {
    color: var(--gold-80);
    background: var(--gold-15);
    border: 1px solid var(--gold-30);

    .fa-asterisk {
      color: var(--viridian-50);
    }
  }

  &--gold-light {
    color: var(--gold-80);
    background: var(--gold-5);
    border: 1px solid var(--gold-20);

    .fa-asterisk {
      color: var(--viridian-50);
    }
  }

  &--viridian {
    color: var(--gray-0);
    background: var(--viridian-50);
    border: 1px solid var(--viridian-80);
  }

  &--viridian-light {
    color: var(--viridian-80);
    background: var(--viridian-5);
    border: 1px solid var(--viridian-20);
  }

  &--gray {
    color: var(--gray-80);
    background: var(--gray-20);
    border: 1px solid var(--gray-50);
  }

  &--gray-light {
    color: var(--gray-80);
    background: var(--gray-10);
    border: 1px solid var(--gray-20);
  }

  &--gray-dark {
    color: var(--gray-0);
    background: var(--gray-50);
    border: 1px solid var(--gray-80);
  }

  &--coral {
    color: var(--coral-80);
    background: var(--coral-20);
    border: 1px solid var(--coral-40);
  }

  &--coral-light {
    color: var(--coral-80);
    background: var(--coral-5);
    border: 1px solid var(--coral-20);
  }

  &--periwinkle {
    color: var(--periwinkle-80);
    background: var(--periwinkle-10);
    border: 1px solid var(--periwinkle-40);
  }

  &--periwinkle-light {
    color: var(--periwinkle-80);
    background: var(--periwinkle-5);
    border: 1px solid var(--periwinkle-50);
  }

  &--periwinkle-dark {
    color: var(--periwinkle-80);
    background: var(--periwinkle-20);
    border: 1px solid var(--periwinkle-80);
  }

  &--rust-light {
    color: var(--rust-70);
    background: var(--rust-10);
    border: 1px solid var(--rust-70);
  }

  &--public {
    font-size: 13px;
    line-height: 1.38;

    .fa-asterisk {
      font-size: 12px;
      color: var(--gold-30);
    }

    .fa-chair {
      padding-right: 3px;
      padding-left: 4px;
      font-size: 16px;
    }

    .tag__text {
      padding-right: 4px;
      font-weight: 400;
    }
  }
}

.tag__text {
  display: inline-block;
  white-space: nowrap;

  &::first-letter {
    text-transform: capitalize;
  }
}
</style>
