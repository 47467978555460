<template>
  <div>
    <k-form-field label="Which child are you touring for?">
      <dependents-radio-list v-if="!multiDependents"
        :parentId="parentId"
        v-model="dependentId"
        :ageDisplay=false
        :olderChildren="olderChildren"
      />
      <dependents-checkbox-list v-if="multiDependents"
      v-model="selectedDependents"
      :ageDisplay=false
      :olderChildren="olderChildren"
      />
    </k-form-field>
    <k-form-field label="When do you want to start care?">
      <date-input v-model="startCare" min="today" :max="after5Years()" :dateOnly="true" />
    </k-form-field>
    <k-form-field
      label="Phone number"
      rules="required|mobileNumber"
    >
      <k-input
        rules="required"
        v-model="phoneNumber"
        placeholder="5555555555"
      />
    </k-form-field>
    <div class="textarea-container">
      <k-form-field label="Any additional info you'd like to provide? (optional)">
        <k-textarea v-model="parentComment" id="tour-note" autogrow></k-textarea>
      </k-form-field>
    </div>
  </div>
</template>

<script>
import DateInput from '@components/inputs/date-input.vue';
import KFormField from '@components/forms/form_field.vue';
import KInput from '@components/inputs/input.vue';
import KTextarea from '@components/inputs/textarea.vue';
import DependentsRadioList from '@parent/components/dependent-management/dependents-radio-list.vue';
import DependentsCheckboxList from '@parent/components/dependent-management/dependents-checkbox-list.vue';
import addYears from '@utils/addYears';

export default {
  components: {
    DateInput,
    KFormField,
    KInput,
    KTextarea,
    DependentsRadioList,
    DependentsCheckboxList,
  },
  props: {
    tourData: {
      type: Object,
      default() {
        return {};
      },
    },
    olderChildren: {
      type: Array,
      default() {
        return [];
      },
    },
    multiDependents: {
      type: Boolean,
      default: false,
    },
    bus: {
      type: Object,
      required: true,
    },
    submitStep: {
      type: Function,
      required: true,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      dependentId: this.tourData.dependentId || undefined,
      selectedDependents: this.tourData.selectedDependents || [],
      startCare: this.tourData.startCare || new Date().toISOString(),
      phoneNumber: this.tourData.phoneNumber || '',
      parentComment: this.tourData.parentComment || '',
    };
  },
  created() {
    this.bus.$on('on-next-step', this.handleNext);
  },
  beforeDestroy() {
    this.bus.$off('on-next-step', this.handleNext);
  },
  watch: {
    dependentId() {
      this.onChange(this.$data);
    },
    parentComment() {
      this.onChange(this.$data);
    },
    startCare() {
      this.onChange(this.$data);
    },
    phoneNumber() {
      this.onChange(this.$data);
    },
  },
  computed: {
    parentId() {
      const user = this.$store.state.user.current;
      return user?.isParent ? user.id : undefined;
    },
  },
  methods: {
    handleNext() {
      this.submitStep();
    },
    after5Years() {
      return addYears(new Date(), 5);
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea-container {
  margin-top: calc(var(--grid-unit) * 3);
}
</style>
